import { useEffect } from "react"
import { useSearchParams,Redirect } from "react-router-dom"

const OpenApp=()=>{

    const [searchParams, setSearchParams] = useSearchParams()
    const event_code = searchParams.get('event_code')
    console.log(searchParams,"params")
    useEffect(()=>{
        window.location.href='com.aliceevr.dhammawangsa://redirect_page?'+searchParams
        setTimeout(() => {
            window.location.href='https://play.google.com/store/apps/details?id=com.aliceevr.dhammawangsa';
        },2500)
    },[])

    return(
        <p>Please Wait...</p>
    );
    
}
export default OpenApp;